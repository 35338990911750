import TokenFarm from "../contracts/TokenFarm.json";
import CHTSToken from "../contracts/CHTSToken.json";
import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
} from "../config";

const TOKEN_FARM_ADDRESS = process.env.REACT_APP_DEPOSIT_CONTRACT;
const BUSD_TOKEN_ADDRESS = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;

export const getBUSDTokenContractInstance = (web3) => {
  return new web3.eth.Contract(CHTSToken, BUSD_TOKEN_ADDRESS);
};

export const getTokenFarmContractInstance = (web3) => {
  return new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
};

export const checkBUSDTokensFor = async (web3, account) => {
  const busdContract = getBUSDTokenContractInstance(web3);

  const [balance, allowance] = await Promise.all([
    busdContract.methods.balanceOf(account).call(),
    busdContract.methods.allowance(account, TOKEN_FARM_ADDRESS).call(),
  ]);

  console.log("allowance: ", allowance);

  return {
    balance,
    isApprove: allowance > 100 * 10 ** 18,
  };
};

export const deposit = async (web3, account, totalCoin) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  const coinCHTS = convertToWei(totalCoin.toString(), web3);
  const data = tokenContract.methods.deposit(coinCHTS).send({ from: account });
  return data;
};

export const getHistoryStake = async (web3, account) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);
  try {
    const data = await tokenContract.methods
      .getProfilesByAddress(account)
      .call();

    const history = {};
    data.forEach((item) => {
      const dataA = {
        id: item[0],
        address: item[1],
        amount: +convertToTokens(item[2], web3), // How many tokens the user has provided.
        profitClaimed: +convertToTokens(item[3], web3), // default false
        stakeClaimed: +convertToTokens(item[4], web3), // default false
        vestingStart: item[5],
        vestingEnd: item[6],
        totalProfit: +convertToTokens(item[7], web3),
        packageId: item[8],
        refunded: item[9],
      };
      history[dataA.id] = dataA;
    });

    return history;
  } catch (e) {}

  return [];
};

export const getProfile = async (web3, account) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);

  try {
    const data = await tokenContract.methods.getAllProfiles().call();
    console.log("data: ", data);
    return data;
  } catch (e) {
    console.log("ererere: ", e);
  }

  return [];
};

export const getProfileByAddress = async (web3, account) => {
  const tokenContract = new web3.eth.Contract(TokenFarm, TOKEN_FARM_ADDRESS);

  try {
    const data = await tokenContract.methods.getDataByAddress(account).call();
    return {
      total: +convertToTokens(data.total, web3),
    };
  } catch (e) {
    console.log("ererere: " + account, e);
  }

  return {
    total: 0,
  };
};

export const approveBUSDTokensToTransfer = async (web3, account) => {
  const busdContract = getBUSDTokenContractInstance(web3);

  const coin = convertToWei("100000000", web3);

  const data = await busdContract.methods
    .approve(TOKEN_FARM_ADDRESS, coin)
    .send({ from: account });

  return data;
};

export const convertToTokens = (n, web3) => {
  return web3.utils.fromWei(n, "ether");
};

export const convertToWei = (n, web3) => {
  return web3.utils.toWei(n, "ether");
};
