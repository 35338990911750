import React from "react";
import { Modal, Image } from "semantic-ui-react";
import cardImg from "../../assets/images/bg-modal-1.png";
import closeImg from "../../assets/images/icon-close.svg";

import { formatTotalCoin } from "../../../../utils/helper";

function ModalSetting({ open, toggleModal, balance = 1000, account }) {
  const hiddenAddress = account
    ? `${account[0]}${account[1]}${account[2]}...${
        account[account.length - 3]
      }${account[account.length - 2]}${account[account.length - 1]}`
    : "";

  return (
    <Modal onClose={toggleModal} open={open} id="modal-deposit">
      <div className="inner-modal">
        <Image src={cardImg} className="bg-modal" />

        <div className="modal-header">
          <p className="text-deposite">Account</p>
          <div onClick={toggleModal} className="close-container">
            <Image src={closeImg} className="icon-close" />
          </div>
        </div>
        <div className="flex-row">
          <span className="setting-balance">Your address: </span>

          <span className="setting-balance">{`${hiddenAddress}`}</span>
        </div>
        <div className="flex-row">
          <span className="setting-balance">Your balance: </span>
          <span className="setting-balance">
            {`${formatTotalCoin(balance)} BUSD`}
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSetting;
