import React from "react";
import connectBG from "../../assets/images/presale/btn.png";
import { Button } from "semantic-ui-react";

const CustomButton = ({
  onClick,
  title = "Connect Wallet",
  disabled = false,
}) => {
  return (
    <div
      className="custom-btn"
      style={{
        position: "relative",
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? "no-drop" : "pointer",
      }}
      disabled={disabled}
      onClick={disabled ? null : onClick}
    >
      <img src={connectBG} className="bg-button" alt="bg-wallet" />
      <span
        style={{
          wordBreak: "break-all",
          color: "#dadada !important",
          fontSize: "1em",
          lineHeight: "1.5em",
          fontWeight: 800,
          cursor: disabled ? "no-drop" : "pointer",
          zIndex: 1,
        }}
      >
        <Button
          className="connect-metamask text-bold"
          style={{ cursor: disabled ? "no-drop" : "pointer" }}
        >
          {title}
        </Button>
      </span>
    </div>
  );
};

export default CustomButton;
