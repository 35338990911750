import React, { useEffect, useState } from "react";
import { Modal, Image } from "semantic-ui-react";
import cardImg from "../../assets/images/bg-modal-1.png";
import closeImg from "../../assets/images/icon-close.svg";
import CustomButton from "../elements/CustomButton";
import { handleChangeNetwork } from "../../../../utils/metamask";

import { formatTotalCoin } from "../../../../utils/helper";

function ModalSwitch({ open, toggleModal }) {
    return (
        <Modal onClose={toggleModal} open={open} id="modal-deposit">
            <div className="inner-modal">
                <div className="modal-header">
                    <div onClick={toggleModal} className="close-container">
                        <Image src={closeImg} className="icon-close" />
                    </div>
                </div>
                <Image src={cardImg} className="bg-modal" />

                <div style={{
                    width: '100%',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: '32px',
                    margin: '40px 0'
                }}>PLEASE CONNECT BSC NETWORK!</div>
                <CustomButton
                    title="Switch network"
                    onClick={handleChangeNetwork}
                />
            </div>
        </Modal>
    );
}

export default ModalSwitch;
