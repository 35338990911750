import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import { Button } from "semantic-ui-react";
import "../../assets/scss/landingPage.scoped.scss";
import {
  handleConnect,
  handleInstall,
  handleChangeNetwork,
} from "../../../../utils/metamask";
import AppContext from "../../../../appContext";
import connectBG from "../../assets/images/bg-wallet.svg";
import { useLocation } from "react-router-dom";

import { isMobile } from "react-device-detect";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  metoTokens,
  daiTokens,
  ...props
}) => {
  const { account, hasWalletAddress, toggleSetting, networkId } =
    useContext(AppContext);

  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  const location = useLocation();

  const hiddenAddress = account
    ? `${account[0]}${account[1]}${account[2]}...${
        account[account.length - 3]
      }${account[account.length - 2]}${account[account.length - 1]}`
    : "";

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const renderMetaMaskLabel = () => {
    if (window.ethereum) {
      return !hasWalletAddress && !account ? (
        <Button
          className="connect-metamask wallet-address"
          onClick={handleConnect}
        >
          Connect Wallet
        </Button>
      ) : (
        <p onClick={toggleSetting} className="wallet-address">
          {hiddenAddress}
        </p>
      );
    } else {
      return isMobile ? (
        <a
          href={process.env.REACT_APP_DEEPLINK_METAMASK}
          className="connect-metamask"
        >
          Connect MetaMask
        </a>
      ) : (
        <Button className="connect-metamask" onClick={handleInstall}>
          Install MetaMask
        </Button>
      );
    }
  };

  const isGuideChosen = location?.pathname === "/guide";

  return (
    <header {...props} className={classes}>
      {!!(
        networkId.toString() !== process.env.REACT_APP_NETWORK_ID.toString() &&
        !isGuideChosen
        && window.ethereum
      ) && (
        <div
          style={{
            color: "black",
            textAlign: "center",
            background: "#ffaa00",
            padding: 10,
            fontSize: 18,
            fontWeight: 800,
          }}
        >
          {`Please connect BSC ${process.env.REACT_APP_NAME_NETWORK} network!`}
          <div onClick={handleChangeNetwork} className="switch-container">
            <span className="switch-txt">SWITCH</span>

            <img
              src="https://market.creaturehunters.world/static/media/btn-switch.cf020bb3.png"
              className="switch-icon"
              alt="guide-board-bg"
            />
          </div>
        </div>
      )}
      <div className="container" style={{ marginTop: 20 }}>
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          {/* <div
            style={{
              flex: 1000000,
              textAlign: "center",
            }}
          >
            <Link
              className={`nav-item ${
                !isGuideChosen ? "has-border-bottom" : ""
              }`}
              to="/"
            >
              Staking
            </Link>
            <Link
              className={`nav-item ${isGuideChosen ? "has-border-bottom" : ""}`}
              to="/guide"
            >
              Guide
            </Link>
          </div> */}

          {!hideNav && (
            <>
              <label className="menu-button-container" htmlFor="menu-toggle" />
              <div
                onClick={isActive ? closeMenu : openMenu}
                className="menu-button"
                style={{ right: 10, opacity: isMobile ? 1 : 0 }}
              />
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
                style={{ opacity: 0, marginLeft: 30 }}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul className="list-reset header-nav-right">
                    <li>
                      <div
                        rel="noopener noreferrer"
                        className="metamask-wallet"
                        style={{
                          // backgroundImage: `url(${connectBG})`,
                          position: "relative",
                        }}
                      >
                        <img
                          src={connectBG}
                          className="bg-wallet"
                          alt="bg-wallet"
                        />
                        <span
                          style={{
                            wordBreak: "break-all",
                            color: "#dadada !important",
                            fontSize: "1em",
                            lineHeight: "1.5em",
                            fontWeight: 800,
                          }}
                        >
                          {renderMetaMaskLabel()}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
