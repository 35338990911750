/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import ScrollReveal from "./utils/ScrollReveal";
import AppContext from "../../appContext";
import "../landingPage/assets/scss/landingPage.scoped.scss";
import {
  approveBUSDTokensToTransfer,
  checkBUSDTokensFor,
  convertToTokens,
  deposit,
  getProfileByAddress,
} from "../../utils/assets";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";

import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
} from "../../config";

import { toast } from "react-toastify";

import notifyBG from "../landingPage/assets/images/bg-notify.svg";
import iconSuccess from "../landingPage/assets/images/icon-success.svg";

import ModalSetting from "./components/sections/ModalSetting";

const LandingPage = () => {
  const { web3, account, handleBlockScreen, openSetting, toggleSetting } =
    useContext(AppContext);
  const childRef = useRef();
  let location = useLocation();

  const Layout =
    LayoutDefault === undefined
      ? (props) => <>{props.children}</>
      : LayoutDefault;

  const [BUSDToken, setBUSDToken] = useState(0);
  const [amountBuy, setAmountBuy] = useState(0);

  const [isApproveToTransfer, setApprove] = useState(0);
  const [historyByPackage, setHistory] = useState({
    [PACKAGE_6_MONTHS]: [],
    [PACKAGE_12_MONTHS]: [],
    [PACKAGE_3_MONTHS]: [],
  });

  const notify = (message, isFailed = false) =>
    toast(
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 5,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* insert your icon here */}
        <img
          src={notifyBG}
          style={{ position: "absolute", zIndex: -1 }}
          alt="notifybg"
        />
        <div className="notify-inner">
          <span
            className={isFailed ? "notify-failed-text" : "notify-success-text"}
            style={{ display: "flex" }}
          >
            <img
              src={iconSuccess}
              alt="notifysuccess"
              style={{
                width: 20,
                height: 20,
                marginRight: 15,
                display: isFailed ? "none" : "inline-block",
              }}
            />
            {message}
          </span>
          <div className="icon-close-notify"> X</div>
        </div>
      </div>,
      { position: toast.POSITION.TOP_CENTER, autoClose: 3500 }
    );

  const handleTokensChange = useCallback(async () => {
    handleBlockScreen(true);
    try {
      const { balance, isApprove } = await checkBUSDTokensFor(web3, account);

      setBUSDToken(+convertToTokens(balance, web3));
      setApprove(isApprove);
      const userData = await getProfileByAddress(web3, account);
      setAmountBuy(userData.total);
    } catch (e) {
    }

    handleBlockScreen(false);
  }, [account, web3]);

  useEffect(() => {
    (async () => {
      //it could happen that account is empty when this useEffect runs initially, hence the guard
      if (account) {
        handleTokensChange();
      } else {
        setHistory({});
      }
    })();
  }, [account, handleTokensChange]);

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onApproveToTransfer = useCallback(async () => {
    handleBlockScreen(true);
    try {
      await approveBUSDTokensToTransfer(web3, account);
      setApprove(true);
    } catch (e) {
      const error = e.message;

      if (error.includes("insufficient funds")) {

        notify("Insufficient BNB", true);
      } else {
        notify("Enable failed", true);
      }
    }
    handleBlockScreen(false);
  }, [web3, account]);

  const onDeposit = useCallback(
    async (coin) => {
      handleBlockScreen(true);
      try {
        await deposit(web3, account, coin);
        handleBlockScreen(false);
        setApprove(true);
        await handleTokensChange();
        return true;
      } catch (e) {
        const error = e.message;
        console.log(e)
        if (error.includes("insufficient funds")) {
          notify("Insufficient BNB", true);
        } else {
          notify("Deposit failed", true);
        }
      }
      handleBlockScreen(false);
      return false;
    },
    [web3, account]
  );

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Layout>
            <Home
              balance={BUSDToken}
              isApproveToTransfer={isApproveToTransfer}
              onApprove={onApproveToTransfer}
              historyByPackage={historyByPackage}
              onDeposit={onDeposit}
              amountBuy={amountBuy}
            />
            <ModalSetting
              open={openSetting}
              balance={BUSDToken}
              account={account}
              toggleModal={toggleSetting}
            />
          </Layout>
        )}
      />
    </>
  );
};

export default LandingPage;
