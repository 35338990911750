import React, { useState, useContext } from "react";
import { Image } from "semantic-ui-react";

import cardImg from "../../assets/images/presale/rectangle.png";
import universeImg from "../../assets/images/presale/universe.svg";
import inputImg from "../../assets/images/presale/input.png";
import webIcon from "../../assets/images/social/website.png";
import fbIcon from "../../assets/images/social/fb.png";
import insIcon from "../../assets/images/social/insta.png";
import twiIcon from "../../assets/images/social/twi.png";
import linkIcon from "../../assets/images/social/link.png";
import tubeIcon from "../../assets/images/social/tube.png";
import teleIcon from "../../assets/images/social/tele.png";
import disIcon from "../../assets/images/social/dis.png";
import medIcon from "../../assets/images/social/medium.png";

import CustomButton from "../elements/CustomButton";
import RangeSlider from "./RangeSlider";
import ModalSwitch from "./ModalSwitch";
import AppContext from "../../../../appContext";
import { handleConnect, handleChangeNetwork } from "../../../../utils/metamask";

const MAXIMUM_NUMBER = 1750;

function Transaction({
  isApproveToTransfer,
  onApprove,
  onDeposit,
  amountBuy,
  balance = 0,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const { openSwitchModal, toggleSwitchModal } = useContext(AppContext);

  const maximumUserCanBuyTemp = MAXIMUM_NUMBER - amountBuy;
  const maximumUserCanBuy = MAXIMUM_NUMBER - amountBuy;

  const { account, networkId } = useContext(AppContext);

  const [step, setStep] = useState(1);

  const [coin, setCoin] = useState("");

  const [err, setErr] = useState("");

  const sliderValueChanged = (val) => {
    const value = (maximumUserCanBuy * val) / 100;
    if (value > maximumUserCanBuy || value > balance) {
      setErr("Not enough BUSD");
    } else if (err) {
      setErr("");
    }
    setCoin((maximumUserCanBuy * val) / 100);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const renderConnectWallet = () => (
    <>
      {" "}
      <Image src={universeImg} style={{ width: 300 }} />
    </>
  );

  const handlePrevStep = () => setStep(step - 1);

  const handleChangeCoin = (e) => {
    console.log(maximumUserCanBuy);
    if (
      e.target.value > maximumUserCanBuy ||
      e.target.value < 0 ||
      e.target.value > balance
    ) {
      setErr("Not enough BUSD");
    } else if (err) {
      setErr("");
    }

    setCoin(Number(e.target.value));
  };

  const handleDeposit = async () => {
    if (!coin) {
      return;
    }

    const isSuccess = await onDeposit(coin);

    if (isSuccess) {
      setStep(step + 1);
      setCoin(0);
    }
  };

  const renderStepDeposit = () => (
    <>
      <div className="input-container">
        <Image src={inputImg} className="bg-modal" />
        <input
          className="input-field-deposit"
          type="number"
          placeholder="Amount deposit (BUSD)"
          step="any"
          value={coin.toString()}
          onChange={handleChangeCoin}
        />
        <p className="modal-error">{err}</p>
      </div>
      <div className="mt-2 input-range-container">
        <RangeSlider
          min={0}
          max={100}
          value={(coin / maximumUserCanBuy) * 100}
          step={2}
          label="This is a reusable slider"
          onChange={(e) => sliderValueChanged(e)}
          classes="additional-css-classes"
        />
      </div>
      <div className="flex justify-between">
        <span className="value-range">0</span>
        <span className="value-range">{`${maximumUserCanBuy} BUSD`}</span>
      </div>

    </>
  );

  const renderStepSuccess = () => (
    <p className="transaction-successfull">Transaction successfull</p>
  );

  const title = account
    ? isApproveToTransfer
      ? step === 1
        ? "Buy"
        : "Back"
      : "Enable"
    : "Connect Wallet";

  const flexCenter = account
    ? {}
    : { display: "flex", flexDirection: "column", alignItems: "center" };

  return (
    <div className={`container-transaction`}>
      <Image src={cardImg} className="bg-modal" />

      {amountBuy >= MAXIMUM_NUMBER ?
        <div className={`container-transaction-inner`}>
          <div className="transaction-complete-note" style={{
            textAlign: 'center'
          }}>
            You have reached the maximum amount of BUSD that you can buy <span style={{ color: '#0BD2E2' }}>(1,750 BUSD).</span>
          </div>
          <div className="transaction-complete-thank justify-center" style={{
            textAlign: 'center'
          }}>
            Thank You for your participation!
          </div>
          <div className="transaction-complete-upcoming" style={{
          }}>
            Creature Hunters Upcoming Progression
          </div>

          <div style={{
            display: 'flex', justifyContent: 'space-between', marginTop: '20px',
            fontSize: isMobile ? '15px' : '18px', width: '100%'
          }}>
            <span style={{ flex: 1, textAlign: 'start' }} >IDO Launching at NFTb Launchpad: </span>
            <span style={{ flex: 1, textAlign: 'center' }} >00:00 UTC - 23:59 UTC</span>
            <span style={{ flex: 1, textAlign: 'center' }} >Mar 24, 2022</span>
          </div>

          <div style={{
            display: 'flex', justifyContent: 'space-between', marginTop: '20px',
            fontSize: isMobile ? '15px' : '18px', width: '100%'
          }}>
            <span style={{ flex: 1, textAlign: 'start' }} >Listing DEX - Pancakeswap:   </span>
            <span style={{ flex: 1, textAlign: 'center' }} >01:00 UTC	</span>
            <span style={{ flex: 1, textAlign: 'center' }} >Mar 25, 2022</span>
          </div>

          <div className="transaction-complete-upcoming" style={{
          }}>
            Follow our networks for latest announcement
          </div>
          <div className="justify-center" style={{
            textAlign: 'center',
            justifyContent: 'center',
            margin: '20px',
            display: 'flex'
          }}>
            <a href="https://creaturehunters.world/" target="_blank" className="social">
              <Image src={webIcon} />
            </a>
            <a href="https://www.facebook.com/creaturehunters.io" target="_blank" className="social">
              <Image src={fbIcon} />
            </a>
            <a href="https://www.instagram.com/creaturehunters/" target="_blank" className="social">
              <Image src={insIcon} />
            </a>
            <a href="https://twitter.com/CREATUREHUNTER5" target="_blank" className="social">
              <Image src={twiIcon} />
            </a>
            <a href="https://www.linkedin.com/company/creature-hunters" target="_blank" className="social">
              <Image src={linkIcon} />
            </a>
            <a href="https://www.youtube.com/channel/UCkHSaxsEePo4mBLFqBP1EAg" target="_blank" className="social">
              <Image src={tubeIcon} />
            </a>
            <a href="https://t.me/creaturehunters" target="_blank" className="social">
              <Image src={teleIcon} />
            </a>
            <a href="https://discord.gg/N2dXHVa5uu" target="_blank" className="social">
              <Image src={disIcon} />
            </a>
            <a href="https://creaturehunters.medium.com/" target="_blank" className="social">
              <Image src={medIcon} />
            </a>
          </div>

        </div>
        : <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: "100%",
            ...flexCenter,
          }}
        >
          {account
            ? step == 1
              ? renderStepDeposit()
              : renderStepSuccess()
            : renderConnectWallet()}
          <div
            className="flex justify-between"
            style={{ marginBottom: 10, opacity: amountBuy ? 1 : 0 }}
          >
            <span className="value-range text-yellow">Deposited</span>
            <span className="value-range text-yellow">{`${amountBuy} BUSD`}</span>
          </div>
          <div className="mt-3  ">
            {account && (
              <div className="progress-container">
                {[1, 2, 3].map((item, index) => (
                  <div
                    className={`progress-inner ${(index <= step && isApproveToTransfer) ||
                      (!isApproveToTransfer && index == 0)
                      ? "progress-active"
                      : ""
                      }`}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: 30,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ minWidth: "40%" }}>
              <ModalSwitch
                open={openSwitchModal}
                toggleModal={toggleSwitchModal}
              />
              {window.ethereum ? (
                networkId != process.env.REACT_APP_NETWORK_ID ? (
                  <CustomButton
                    title="Buy"
                    disabled={err ? true : false}
                    onClick={toggleSwitchModal}
                  />
                ) : (
                  <CustomButton
                    title={title}
                    disabled={err ? true : false}
                    onClick={
                      account
                        ? isApproveToTransfer
                          ? step == 2
                            ? handlePrevStep
                            : handleDeposit
                          : onApprove
                        : handleConnect
                    }
                  />
                )
              ) : (
                <a
                  href={process.env.REACT_APP_DEEPLINK_METAMASK}
                  className="connect-metamask"
                >
                  <CustomButton
                    title="Connect Metamask"
                    onClick={handleConnect}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Transaction;
