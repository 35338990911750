import React from "react";
import { Grid } from "semantic-ui-react";

import bgImage from "../../assets/images/guide-board.png";

const fakeData = [
  {
    id: 1,
    data: ["March 25, 2022", "8%", "4,000"],
  },
  {
    id: 2,
    data: [`April 25, 2022`, "18.4%", "9,200"],
  },
  {
    id: 3,
    data: [`May 25, 2022`, "18.4%", "9,200"],
  },
  {
    id: 4,
    data: [`June 25, 2022`, "18.4%", "9,200"],
  },
  {
    id: 5,
    data: [`July 25, 2022`, "18.4%", "9,200"],
  },
  {
    id: 6,
    data: [`August 25, 2022`, "18.4%", "9,200"],
  },
];

const GuideDetail = () => (
  <Grid columns={3} className="guide-board-container no-shadow">
    {/* <img src={bgImage} className="guide-board-bg" alt="guide-board-bg" /> */}
    <Grid.Row
      className="no-shadow bg-inner-guide radius-top margin-guide-header"
      style={{ alignItems: "center" }}
    >
      <Grid.Column textAlign="center" className="no-shadow">
        <p style={{ fontSize: 14 }}>Date</p>
      </Grid.Column>
      <Grid.Column textAlign="center" className="no-shadow">
        <p style={{ fontSize: 14 }}>Proportion %</p>
      </Grid.Column>
      <Grid.Column textAlign="center" className="no-shadow">
        <p style={{ fontSize: 14, marginBottom: 0 }}>Actual CHTS Amount</p>
        
        <p style={{ fontSize: 14, marginBottom: 0 }}>*if order 50,000 CHTS</p>
      </Grid.Column>
    </Grid.Row>
    {/* <div className="line-guide" /> */}
    {fakeData.map((item, index) => (
      <Grid.Row
        columns={3}
        key={item.id.toString()}
        style={{ border: "none", alignItems: "center" }}
        className={`no-shadow bg-inner-guide margin-guide-content ${
          index % 2 ? "bg-A" : "bg-B"
        }`}
      >
        <Grid.Column textAlign="center" className={`no-shadow no-padding`}>
          <p className={`guide-text none-margin-left `}>
            {item.data[0]}
          </p>
        </Grid.Column>
        <Grid.Column textAlign="center" className={`no-shadow no-padding`}>
          <p className={`guide-text`}>{item.data[1]}</p>
        </Grid.Column>
        <Grid.Column textAlign="center" className={`no-shadow no-padding`}>
          <div className={`guide-text`}>{item.data[2]}</div>
        </Grid.Column>
      </Grid.Row>
    ))}
    {/* <Grid.Row>
      <Grid.Column width={3}>
        <p>1</p>
      </Grid.Column>
      <Grid.Column width={3}>
        <p>3 Months Staking</p>
      </Grid.Column>
      <Grid.Column width={3}>
        <p>6 Months Staking</p>
      </Grid.Column>
      <Grid.Column width={3}>
        <p>12 Months Staking</p>
      </Grid.Column>
    </Grid.Row> */}
  </Grid>
);

export default GuideDetail;
