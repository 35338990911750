import React, { useState, useEffect, memo } from "react";

const RangeSlider = memo(
  ({ classes, label, onChange, value, ...sliderProps }) => {
    const [sliderVal, setSliderVal] = useState(0);
    const [mouseState, setMouseState] = useState(null);

    useEffect(() => {
      setSliderVal(value);
    }, [value]);

    const changeCallback = (e) => {
      console.log(e.target.value)
      setSliderVal(e.target.value);
      onChange(e.target.value)
    };

    // useEffect(() => {
    //   if (sliderVal) {
    //     console.log("aaaaaaaaaaaaaaa")
    //     onChange(sliderVal);
    //   }
    // }, [ onChange, sliderVal]);
    return (
        <input
          type="range"
          value={sliderVal}
          {...sliderProps}
          className={`slider ${classes}`}
          id="myRange"
          onChange={changeCallback}
          onMouseDown={() => setMouseState("down")}
          onMouseUp={() => setMouseState("up")}
        />
    );
  }
);

export default RangeSlider;
