import React, { useState } from "react";
import Hero from "../components/sections/Hero";
import Transaction from "../components/sections/Transaction";
import GuideDetail from "../components/sections/GuideDetail";

const CONTENT = {
  1: {
    title: "PROJECT OVERVIEW",
    content:
      "Creature Hunters is an online puzzle action blockchain game, based on the original animation produced by the world-famous producer Hitosi Morgi. The game is built for players to take on new challenges every day with various game modes, including Auto-matches, PvP Battles, and Question Missions. Creature Hunters guarantees to be one of the best NFT games, allowing players to have fun, acquire exclusive and tradeable rewards, and play-to-earn profitable CHTS.",
  },
  2: {
    title: "CHTS DETAILS",
    contents: [
      { title: "Access Type", content: "Whitelist winner" },
      {
        title: "Token Distribution",
        content: "8% TGE the equal vesting for 5 month",
      },
      { title: "Token Price", content: "0.035 $" },
      { title: "TGE Market Cap", content: "672.000 $" },
      { title: "Number of winner", content: "100" },
      {
        title: "Pre-Sale  Whitelist Ticket",
        content: "50,000 CHTS (US$ 1,750)",
      },
    ],
  },
  3: {
    title: "TOKEN IFNORMTION",
    contents: [
      { title: "Symbol", content: "CHTS" },
      {
        title: "Total Supply",
        content: "500.000.000",
      },
      {
        title: "Contract Address",
        content: process.env.REACT_APP_CHTS_TOKEN_ADDRESS,
      },
    ],
  },
  4: {
    title: "HOW TO BUY CHTS",
    contents: [
      "1. Click “CONNECT WALLET” to start the purchase process.",

      '2. Click "Enable" (for the first-ever attempt).',

      '3. Fill in the amount of BUSD that you want to pay for CHTS and click "Buy" to finalize the Transaction.',

      "4. Congratulations! You have successfully registered to purchase CHTS. Your CHTS will be distributed directly to your Wallet after our IDO.",
      "*Note: Please also refer to the table below for Periodical Distribute Timeline.",
    ],
  },
};

const Home = ({
  onApprove,
  isApproveToTransfer,
  onDeposit,
  amountBuy,
  balance,
}) => {
  return (
    <>
      <Hero />
      <div
        className="container ui doubling stackable two column grid"
        id="main-page"
      >
        <div id="left">
          <div>
            <div className="flex justify-between mb-1">
              <h1 className="title">PROJECT OVERVIEW</h1>
              {/* <span className="txt-close">X</span> */}
            </div>
            <p style={{ fontSize: 16 }}>{CONTENT[1].content}</p>
          </div>
          <div className="mt-16">
            <div className="flex justify-between mb-1">
              <h1 className="title">INFORMATION</h1>
              {/* <span className="txt-close">X</span> */}
            </div>

            <p className="subtitle">CHTS DETAILS</p>
            <div className="table">
              {CONTENT[2].contents.map((item, index) => (
                <div
                  key={item.title}
                  className={`flex justify-between row ${index == CONTENT[2].contents.length - 1
                    ? "no-border-bottom"
                    : ""
                    }`}
                >
                  <span className="content-left">{item.title}</span>
                  <span className="content-right">{item.content}</span>
                </div>
              ))}
            </div>

            <p className="subtitle mt-5">TOKEN INFORMATION</p>
            <div className="table">
              {CONTENT[3].contents.map((item, index) => (
                <div
                  key={item.title}
                  className={`flex justify-between row ${index == CONTENT[3].contents.length - 1
                    ? "no-border-bottom"
                    : ""
                    }`}
                >
                  <span className="content-left">{item.title}</span>

                  <div
                    style={{ maxWidth: "50%" }}
                    className="content-right div-wrap"
                  >
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${process.env.REACT_APP_LINK_BSC}/${process.env.REACT_APP_CHTS_TOKEN_ADDRESS}`}
                    >
                      {" "}
                      {item.content}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="right">
          <Transaction
            isApproveToTransfer={isApproveToTransfer}
            onApprove={onApprove}
            onDeposit={onDeposit}
            amountBuy={amountBuy}
            balance={balance}
          />
          <div className="content-how-to-buy mt-2">
            <p className="title mt-5" style={{ textAlign: "center" }}>
              HOW TO BUY CHTS
            </p>
            <div style={{ marginTop: '20px' }}>
              {CONTENT[4].contents.map((item, index) => (
                <p key={index} className="content-left" style={{ fontSize: 16 }}>
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          position: "relative",
          textAlign: "center",
          marginTop: 20,
          marginBottom: 50,
        }}
      >
        <h1
          className="mt-0 mb-16 reveal-from-bottom text-primary text-white"
          data-reveal-delay="200"
        >
          Periodical Distribute Timeline{" "}
        </h1>
        <GuideDetail />
      </div>
    </>
  );
};

export default Home;
